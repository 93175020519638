import { API_URL } from '../config';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // Importing UUID library
import PopupAlert from '../components/PopupAlert'; // Import the PopupAlert component
import './sheetEdit.css';

const SheetEdit = () => {
  const { sheetId } = useParams();
  const [sheets, setSheets] = useState([]);
  const [selectedSheetId] = useState(sheetId);
  const [selectedProductIds, setSelectedProductIds] = useState([]); // Updated to handle multiple selections
  const [newSheetName, setNewSheetName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [options, setOptions] = useState({
    seriesOptions: [],
    memoryOptions: [],
    conditionOptions: [],
    simOptions: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isNameEdited, setIsNameEdited] = useState(false);
  const [duplicateProducts, setDuplicateProducts] = useState([]);
  const [isShaking, setIsShaking] = useState(false); // State for shake animation

  useEffect(() => {
    const fetchSheets = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/sheets`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSheets(response.data);
        const selectedSheet = response.data.find(sheet => sheet._id === sheetId);
        if (selectedSheet) {
          setNewSheetName(selectedSheet.name);
        }
      } catch (error) {
        setErrorMessage('Error fetching sheets');
        console.error('Error fetching sheets:', error);
      }
    };

    const fetchUniqueOptions = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/products`);
        const products = response.data;

        const uniqueSeries = [...new Set(products.map(product => product.longest_model))];
        const uniqueMemory = [...new Set(products.map(product => product.storage))];
        const uniqueConditions = [...new Set(products.map(product => product.condition))];
        const uniqueSims = [...new Set(products.map(product => product.sim))];

        setOptions({
          seriesOptions: uniqueSeries,
          memoryOptions: uniqueMemory,
          conditionOptions: uniqueConditions,
          simOptions: uniqueSims
        });
        setIsLoading(false);
      } catch (error) {
        setErrorMessage('Error fetching unique options');
        setIsLoading(false);
        console.error('Error fetching unique options:', error);
      }
    };

    fetchSheets();
    fetchUniqueOptions();
  }, [sheetId]);

  useEffect(() => {
  }, [sheets]);

  const handleSheetNameChange = async () => {
    try {
      const token = localStorage.getItem('token');
      const sheetToRename = sheets.find(sheet => sheet._id === selectedSheetId);
      sheetToRename.name = newSheetName;
      await axios.put(`${API_URL}/api/sheets/${selectedSheetId}`, sheetToRename, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSheets(sheets.map(sheet => sheet._id === selectedSheetId ? sheetToRename : sheet));
      setIsNameEdited(false);
      setErrorMessage('');
      setSuccessMessage('Sikeres Mentés');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      setErrorMessage('Hiba történt a mentés közben.');
      console.error('Error renaming the sheet:', error);
    }
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProductIds(prevState => {
      if (prevState.includes(productId)) {
        return prevState.filter(id => id !== productId);
      } else {
        return [...prevState, productId];
      }
    });
  };

  const handleProductChange = (productId, field, value) => {
    setSheets(prevSheets =>
      prevSheets.map(sheet =>
        sheet._id === selectedSheetId
          ? {
              ...sheet,
              products: sheet.products.map(product =>
                product._id === productId ? { ...product, [field]: value } : product
              )
            }
          : sheet
      )
    );
  };

  const handleSave = async () => {
    if (duplicateProducts.length > 0) {
      setIsShaking(true);
      setErrorMessage('Duplikált termék található. Kérjük távolítsa el.');
      setTimeout(() => {
        setIsShaking(false);
        setErrorMessage('');
      }, 3000);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const sheetToSave = sheets.find(sheet => sheet._id === selectedSheetId);
      await axios.put(`${API_URL}/api/sheets/${selectedSheetId}`, sheetToSave, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccessMessage('Sikeres Mentés');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      setErrorMessage('Hiba történt a mentés közben.');
      console.error('Error saving the sheet:', error);
    }
  };

  const handleDeleteSelected = () => {
    setSheets(prevSheets =>
      prevSheets.map(sheet =>
        sheet._id === selectedSheetId
          ? { ...sheet, products: sheet.products.filter(product => !selectedProductIds.includes(product._id)) }
          : sheet
      )
    );
    setSelectedProductIds([]); // Clear the selected products
  };

  const handleAddProduct = () => {
    const newProduct = { _id: uuidv4(), series: '', memory: '', condition: '', sim: '', price: '' }; // Generate unique ID
    setSheets(prevSheets =>
      prevSheets.map(sheet =>
        sheet._id === selectedSheetId
          ? { ...sheet, products: [...sheet.products, newProduct] }
          : sheet
      )
    );
  };

  const checkDuplicates = () => {
    const selectedSheet = sheets.find(sheet => sheet._id === selectedSheetId);
    if (!selectedSheet) return false;

    const productKeys = selectedSheet.products.map(product =>
      `${product.series}-${product.memory}-${product.condition}-${product.sim}`
    );
    const duplicates = productKeys.filter((key, index) => productKeys.indexOf(key) !== index);

    setDuplicateProducts(duplicates);
    return duplicates.length > 0;
  };

  useEffect(() => {
    checkDuplicates();
  }, [sheets, selectedSheetId]);

  const selectedSheet = sheets.find(sheet => sheet._id === selectedSheetId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sheet-edit">
      <h2>Szerkesztő</h2>
      <PopupAlert message={successMessage} type="success" visible={!!successMessage} />
      <PopupAlert message={errorMessage} type="error" visible={!!errorMessage} />
      <input
        type="text"
        value={newSheetName}
        onChange={(e) => {
          setNewSheetName(e.target.value);
          setIsNameEdited(true);
        }}
        placeholder="New sheet name"
      />
      {isNameEdited && <button className='sheet-save-name' onClick={handleSheetNameChange}>Save Sheet Name</button>}
      {selectedSheetId && selectedSheet && (
        <>
          <table className="product-table">
            <thead>
              <tr>
                <th></th>
                <th>Termék</th>
                <th>Tárhely</th>
                <th>Állapot</th>
                <th>Szolgáltató</th>
                <th>Eladási Ár</th>
              </tr>
            </thead>
            <tbody>
              {selectedSheet.products.map(product => (
                <tr key={product._id} className={duplicateProducts.includes(`${product.series}-${product.memory}-${product.condition}-${product.sim}`) ? 'duplicate' : ''}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedProductIds.includes(product._id)}
                      onChange={() => handleCheckboxChange(product._id)}
                    />
                  </td>
                  <td>
                    <select
                      value={product.series}
                      onChange={(e) => handleProductChange(product._id, 'series', e.target.value)}
                    >
                      <option value="">Select series</option>
                      {options.seriesOptions.map(series => (
                        <option key={series} value={series}>{series}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={product.memory}
                      onChange={(e) => handleProductChange(product._id, 'memory', e.target.value)}
                    >
                      <option value="">Select memory</option>
                      {options.memoryOptions.map(memory => (
                        <option key={memory} value={memory}>{memory}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={product.condition}
                      onChange={(e) => handleProductChange(product._id, 'condition', e.target.value)}
                    >
                      <option value="">Select condition</option>
                      {options.conditionOptions.map(condition => (
                        <option key={condition} value={condition}>{condition}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={product.sim}
                      onChange={(e) => handleProductChange(product._id, 'sim', e.target.value)}
                    >
                      <option value="">Select SIM</option>
                      {options.simOptions.map(sim => (
                        <option key={sim} value={sim}>{sim}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={product.price}
                      onChange={(e) => handleProductChange(product._id, 'price', e.target.value)}
                      placeholder="Enter price"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="buttons">
            <button
              onClick={handleSave}
              className={`save-prices ${isShaking ? 'shake' : ''}`}
              style={{ backgroundColor: duplicateProducts.length > 0 ? 'red' : '#28a4dd' }}
              title={duplicateProducts.length > 0 ? 'Duplicate products found' : ''}
            >
              Mentés
            </button>
            <button onClick={handleAddProduct} className="add-product">Új Termék</button>
            {selectedProductIds.length > 0 && (
              <button onClick={handleDeleteSelected} className="delete-products">Törlés</button>
            )}
          </div>
          {duplicateProducts.length > 0 && <PopupAlert message="Duplikált termék található. Kérjük távolítsa el." type="error" visible={true} />}
        </>
      )}
    </div>
  );
};

export default SheetEdit;
