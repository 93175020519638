import { API_URL } from '../config';
import { useState, useEffect } from 'react';
import axios from 'axios';

const useActiveSheet = () => {
  const [activeSheet, setActiveSheet] = useState(null);
  const [error, setError] = useState(null);

  const fetchActiveSheet = async () => {
    const activeSheetId = localStorage.getItem('activeSheetId');
    if (!activeSheetId) return;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/sheets/${activeSheetId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setActiveSheet(response.data);
    } catch (error) {
      setError('Error fetching active sheet');
      if (error.response && error.response.status === 404) {
        localStorage.removeItem('activeSheetId');
      }
    }
  };

  useEffect(() => {
    fetchActiveSheet();
  }, []);

  const activateSheet = async (sheetId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${API_URL}/api/sheets/activate/${sheetId}`, null, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setActiveSheet(response.data);
      localStorage.setItem('activeSheetId', response.data._id); // Store the active sheet ID
    } catch (error) {
      setError('Error activating sheet');
    }
  };

  return { activeSheet, error, activateSheet };
};

export default useActiveSheet;
